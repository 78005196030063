// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/* custom.css */
.sc-dkHyXG, .jrYwzF, .rdt_TableHeadRow {
    padding: 0 !important; /* Use !important to override any existing styles */
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/Custom.css"],"names":[],"mappings":";AACA,eAAe;AACf;IACI,qBAAqB,EAAE,mDAAmD;EAC5E","sourcesContent":["\n/* custom.css */\n.sc-dkHyXG, .jrYwzF, .rdt_TableHeadRow {\n    padding: 0 !important; /* Use !important to override any existing styles */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
