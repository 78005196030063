import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { API_BASE_URL } from '../constant/apiconstant';
// import Errorbox from "../Component/Errorbox";

const Createaccount = () => {

  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");




  const [error, setError] = useState(false);
  const [sperror, setspError] = useState(false);
  // const [error, setError] = useState(false);
  // const [role, setSelectedRole] = useState("");

  // if (confirmPassword && password !== confirmPassword) {
  //   // alert("Passwords do not match.");
  //   setError('Passwords do not match!');
  // } else {
  //   setError('');
  //   // return;
  // }

  useEffect(() => {
    if (!validatePassword(password)) {
      setspError('Please enter a strong password. It should be at least 8 characters long and contain uppercase letters, lowercase letters, numbers, and special characters')
      return;
    }
    else {
      setspError('');
    }
  })

  useEffect(() => {

    if (confirmPassword && password !== confirmPassword) {
      setError('Passwords do not match!');
    } else {
      setError('');
    }
  }, [password, confirmPassword]);

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  async function submit(e) {
    e.preventDefault();

    if(error||sperror){
      setError('confirm password do not match')
      return;
    }

    try {
      await axios
        .post(`${API_BASE_URL}user/register`, {
          username,
          email,
          password,
          accountId: "User",
          role: "Agent",
          createdBy: "User",
          isActive: false,
        })

        .then((res) => {
          console.log(res.data);
          navigate("/login");
          setError(false)
        })
        .catch((e) => {
          // alert("Error");
          navigate("/createaccount");
          console.log(e);


        });
    } catch (e) {
      if (error.response) {
        // The request was made and the server responded with a status code
        if (error.response.status === 401) {
          setError("Invalid credentials. Please try again.");
        } else if (error.response.status === 500) {
          setError("Internal server error. Please try again later.");
        } else {
          setError("An error occurred. Please try again.");
        }
      } else if (error.request) {
        // The request was made but no response was received
        setError("No response from the server. Please try again.");
      } else {
        // Something else happened while setting up the request
        setError("An error occurred. Please try again.");
      }
    }



    // if (confirmPassword && password !== confirmPassword) {
    //   // alert("Passwords do not match.");
    //   setError('Passwords do not match!');
    // } else {
    //   setError('');
    //   // return;
    // }
  }

  function validatePassword(password) {
    // Strong password pattern: At least 8 characters, with at least one uppercase letter, one lowercase letter, one number, and one special character.
    const strongPasswordPattern =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
    return strongPasswordPattern.test(password);
  }

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);
  const toggleConfirmPasswordVisibility = () => {
    setshowConfirmPassword(!showConfirmPassword);
  };



  return (
    <div className="container-fluid p-0">
      <div className="row g-0">
        <div className="col-xl-6">
          <div className="auth-full-bg p-4">
            <div className="w-100">
              <div className="bg-overlay"></div>
              <div className="d-flex h-100 flex-column">
                <div className="p-4 m-auto">
                  <div className="row justify-content-center">
                    <div className="col-lg-7">
                      <div className="text-center">
                        <img
                          className="img-fluid"
                          src="assets/images/logo.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- end col --> */}

        <div className="col-xl-6">
          <div className="auth-full-page-content p-md-5 p-4">
            <div className="w-100">
              <div className="d-flex flex-column h-100">
                <div className="mb-4 mb-md-5">
                  <Link to="/dashboard" className="d-block auth-logo">
                    <img
                      src="assets/images/logo-dark.png"
                      alt=""
                      height="18"
                      className="auth-logo-dark"
                    />
                    <img
                      src="assets/images/logo-light.png"
                      alt=""
                      height="18"
                      className="auth-logo-light"
                    />
                  </Link>
                </div>
                <div className="my-auto">
                  <div>
                    <h5 className="text-primary">Register account</h5>
                    <p className="text-muted">
                      Get your free Skote account now.
                    </p>
                  </div>

                  <div className="p-2">
                    <form className="form-horizontal" onSubmit={submit}>

                    { error ||sperror }

                      <div className="row">
                        {/* <div className="col-md-6">
                            <div className="mb-3">
                              <label for="username" className="form-label">
                                Role
                              </label>
                              <select
                                className="form-select"
                                value={role}
                                onChange={(e) => {
                                  setSelectedRole(e.target.value);
                                }}
                                required
                              >
                                <option>Select</option>
                                <option>Role 1</option>
                                <option>Role 2</option>
                                <option value="">Select</option>
                                <option value="role1">Role 1</option>
                                <option value="role2">Role 2</option>
                              </select>
                            </div>
                          </div> */}
                        <div className="col-md-6">
                          {/* <input type="hidden" onChange={(e) => { setUsername(e.target.value) }} value={username}></input>
                                                        <input type="hidden" onChange={(e) => { setIsActive(e.target.value) }} value={IsActive}></input> */}

                          <input type="hidden" name="createdBy" value="USER" />

                          <div className="mb-3">
                            <label for="username" className="form-label">
                              Name
                            </label>

                            <input
                              type="text"
                              className="form-control"
                              id="username"
                              onChange={(e) => {
                                setUsername(e.target.value);
                              }}
                              placeholder="Enter Name"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label for="username" className="form-label">
                              Email ID
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              id="username"
                              onChange={(e) => {
                                setEmail(e.target.value);
                              }}
                              placeholder="Enter Email ID"
                              required
                            />
                          </div>
                        </div>
                        {/* <div className="col-md-6">
                            <div className="mb-3">
                              <label for="username" className="form-label">
                                Mobile No.
                              </label>
                              <input
                                type="tel"
                                className="form-control"
                                placeholder="Enter Mobile No."
                                value={number}
                                maxLength="10"
                                onChange={(e) => {
                                  setNumber(e.target.value);
                                }}
                                required
                              />
                            </div>
                          </div> */}
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Password</label>
                            <div className="input-group auth-pass-inputgroup">
                              <input
                                type={showPassword ? "text" : "password"}
                                className="form-control"
                                onChange={handlePasswordChange}
                                placeholder="Enter password"
                                aria-label="Password"
                                aria-describedby="password-addon"
                                required
                              />
                              <button
                                className="btn btn-light "
                                type="button"
                                onClick={togglePasswordVisibility}
                              >
                                {showPassword ? (
                                  <i className="mdi mdi-eye-outline"></i>
                                ) : (
                                  <i className="mdi mdi-eye-off-outline"></i>
                                )}
                              </button>
                            </div>
                            {sperror && <p style={{ color: 'red' }}>{sperror}</p>}
                          </div>

                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Confirm Password
                            </label>
                            <div className="input-group auth-pass-inputgroup">
                              <input
                                type={showConfirmPassword ? "text" : "password"}
                                className="form-control"
                                onChange={handleConfirmPasswordChange}
                                placeholder="Enter Confirm Password"
                                aria-label="Password"
                                aria-describedby="password-addon"
                                required
                              />
                              <button
                                className="btn btn-light "
                                type="button"
                                onClick={toggleConfirmPasswordVisibility}
                              >
                                {showConfirmPassword ? (
                                  <i className="mdi mdi-eye-outline"></i>
                                ) : (
                                  <i className="mdi mdi-eye-off-outline"></i>
                                )}

                              </button>

                            </div>
                            {error && <p style={{ color: 'red' }}>{error}</p>}

                          </div>
                        </div>
                      </div>
                      <div className="mt-3 text-center">
                        <button
                          className="btn btn-primary waves-effect waves-light btn-rounded px-5"
                          type="submit"
                        >
                          Registered Now
                        </button>
                      </div>
                      <div className="mt-4 text-center">
                        <p>
                          have an account ?{" "}
                          <Link to="/login" className="fw-medium text-primary">
                            {" "}
                            Login{" "}
                          </Link>
                        </p>
                      </div>
                      <div className="mt-4 text-center">
                        <p>© 20 All right reserved. </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- end col --> */}
      </div>
      {/* <!-- end row --> */}
    </div>
  );
};

export default Createaccount;
