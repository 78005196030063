// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* NotFound.css */

.full-screen-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh; /* 100% of the viewport height */
    background-color: #f0f0f0; /* Optional background color */
  }
  
  .full-screen-container img {
    max-width: 100%;
    max-height: 50vh; /* Adjust the height as needed */
  }
  `, "",{"version":3,"sources":["webpack://./src/Component/NotFound.css"],"names":[],"mappings":"AAAA,iBAAiB;;AAEjB;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,aAAa,EAAE,gCAAgC;IAC/C,yBAAyB,EAAE,8BAA8B;EAC3D;;EAEA;IACE,eAAe;IACf,gBAAgB,EAAE,gCAAgC;EACpD","sourcesContent":["/* NotFound.css */\n\n.full-screen-container {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    height: 100vh; /* 100% of the viewport height */\n    background-color: #f0f0f0; /* Optional background color */\n  }\n  \n  .full-screen-container img {\n    max-width: 100%;\n    max-height: 50vh; /* Adjust the height as needed */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
